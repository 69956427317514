import { defineStore } from "pinia"
import { useSessionStore } from "@/stores/session"
import { useI18nStore } from "@/stores/i18n"

export const useRoleStore = defineStore("role", () => {
    const roles = [
        { type: "Admin", text: useI18nStore().RoleBadge.Admin },
        { type: "Monitor", text: useI18nStore().RoleBadge.Monitor },
        {
            type: "CourseCaptain",
            text: useI18nStore().RoleBadge.CourseCaptain,
        },
        { type: "Onboarder", text: useI18nStore().RoleBadge.Onboarder },
    ]

    function can(action: string): boolean {
        // Example how to use in template
        // v-if="role.can('view-faq')"
        let can = false
        const role = useSessionStore().profile.type

        // This array contains all actions that are restricted for certain
        // user roles
        const permissions = [
            {
                role: "Monitor",
                actions: [
                    "manage-chats",
                    "manage-coaches",
                    "manage-schedule",
                    "manage-students",
                    "manage-user-labels",
                    "view-archive",
                ],
            },
            {
                role: "CourseCaptain",
                actions: ["manage-chats", "view-archive"],
            },
            {
                role: "Onboarder",
                actions: [],
            },
            {
                role: "Admin",
                actions: [
                    "manage-chats",
                    "manage-coaches",
                    "manage-coupon-codes",
                    "manage-internal-users",
                    "manage-organisations",
                    "manage-push-notifications",
                    "manage-schedule",
                    "manage-students",
                    "manage-user-labels",
                    "view-archive",
                    "view-coupon-codes",
                ],
            },
        ]

        // Logic for checking whether a user can do a certain action
        if (role === "Admin") {
            // Admins can do everything
            can = true
        } else {
            // Check if permissions array contains action for user role
            for (let permission of permissions) {
                if (permission.role === role) {
                    for (let item of permission.actions) {
                        if (item === action) {
                            can = true
                            break
                        }
                    }
                    break
                }
            }
        }

        return can
    }

    return { roles, can }
})
