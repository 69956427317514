import { defineStore } from "pinia"

export const useI18nStore = defineStore("i18n", {
    state: () => {
        return {
            Account: {
                heading: "Profiel",
                uploadAvatarButtonText: "Foto uploaden",
                personalInfo: {
                    heading: "Persoonlijke gegevens",
                    firstNameLabel: "Voornaam",
                    prefixLabel: "Tussenvoegsel(s)",
                    lastNameLabel: "Achternaam",
                    emailLabel: "E-mailadres",
                    landingPage: "Landingspagina",
                },
                submitButtonText: "Opslaan",
                successMessage: "Wijziging succesvol opgeslagen",
                passwordHeading: "Wachtwoord wijzigen",
                logOutButtonText: "Uitloggen",
                setUp2FA: "2FA instellen",
            },
            AccountActivate: {
                heading: "Account activeren",
                paragraph:
                    "Voer hieronder een nieuw wachtwoord in om je account te activeren.",
                submitButtonText: "Activeer mijn account",
            },
            AccountResetPassword: {
                heading: "Wachtwoord resetten",
                paragraph:
                    "Voer hieronder je nieuwe wachtwoord twee keer in om deze op te slaan.",
                submitButtonText: "Opslaan",
            },
            AccountSetUp2FA: {
                heading: "2FA instellen ",
                paragraph1:
                    "Scan de QR code met Google Authenticator of Authy om 2FA in te stellen.",
                paragraph2: "Voer de code in.",
                submitButtonText: "Verifiëren",
            },
            ChatCloseModal: {
                heading: "Chat beëindigen",
                paragraphBegin: "Weet je zeker dat je chat #",
                paragraphEnd: "geforceerd wilt sluiten?",
                cancelButtonText: "Annuleren",
                submitButtonText: "Beëindigen",
                alertSuccessMessage: "De chat is gesloten.",
            },
            ChatHeader: {
                activeSince: "Actief sinds",
                study: "School",
                studyLevelAndYear: "Leerniveau + jaar",
                chatID: "Chat ID",
                chatDuration: "Gespreksduur",
                chatWaitingTime: "Wachttijd",
                closeChatButtonText: "Chat beëindigen",
                transferChatButtonText: "Chat overdragen",
                chatState: {
                    closed: "Gesloten",
                    active: "Actief",
                },
                LLMPhaseHasBeenEnded:
                    "De LLM fase is beëindigd, en de chat wordt nu aan echte coaches aangeboden.",
                goToCoachReason: 'Reden naar coach',
                flags: "Flags",
            },
            ChatTransferModal: {
                heading: "Chat overdragen",
                paragraph: "Selecteer coach",
                cancelButtonText: "Annuleren",
                submitButtonText: "Overdragen",
                alertSuccessMessage: "De chat is succesvol overgedragen.",
            },
            ChatsArchive: {
                heading: "Archief",
            },
            ChatsArchiveTable: {
                coursesFilter: {
                    emptyOptionText: "Vakken",
                    submitButtonText: "Toepassen",
                },
                orgsFilter: {
                    emptyOptionText: "Organisaties",
                    submitButtonText: "Toepassen",
                },
                coachesFilter: {
                    emptyOptionText: "Coaches",
                    submitButtonText: "Toepassen",
                },
                ratingsFilter: {
                    emptyOptionText: "Ratings",
                    submitButtonText: "Toepassen",
                },
                searchInputPlaceholder: "Zoeken",
                externalLabel: "Extern",
                internalLabel: "Intern",
                resetButtonText: "Reset",
                numberOfChatsFound: {
                    begin: "Er zijn ",
                    end: " chats gevonden",
                },
                loadMoreResultsButtonText: "Laat meer chats zien",
                columns: {
                    id: "ID",
                    status: "Status",
                    check: "✔️",
                    course: "Vak",
                    start: "Start",
                    student: "Leerling",
                    organisation: "Org.",
                    coach: "Coach",
                    waitingTime: "Wachtt.",
                    duration: "Gesp. duur",
                    rating: "Rating",
                    flags: "Flags"
                },
                feedbackTooltipHeading: "Feedback",
            },
            ChatsGridView: {
                noResults: "Er zijn op dit moment geen live chats...",
            },
            ChatsLive: {
                heading: "Live chats",
                kpiLabels: {
                    chats: "Aantal chats",
                    externalChatsLabel: "Extern",
                    internalChatsLabel: "Intern",
                    stateHeading: "Status",
                    chatbot: "Chatbot",
                    chatbotLLM: "LLM",
                    waiting: "Wachten",
                    active: "Actief",
                    averagesHeading: "Gemiddelden",
                    averageWaitingTime: "Wachttijd",
                    averageChatDuration: "Gespreksduur",
                },
                columns: {
                    chatID: "ID",
                    state: "Fase",
                    course: "Vak",
                    userCreated: "Leerling",
                    monitor: "Monitor",
                    organisation: "Org.",
                    startedAt: "Start",
                    waitingTime: "Wachtt.",
                    duration: "Gesp. duur",
                    coach: "Coach",
                    transferedFrom: "Overgedragen van",
                    flags: "Flags",
                },
                labelHeading: "Labels",
                noResultsText: "Er zijn op dit moment geen live chats",
            },
            CoachDetail: {
                tabItems: ["Algemeen", "Chats", "Rooster", "Export"],
                linkToHomeText: "Home",
                linkToCoachesListText: "Alle coaches",
            },
            CoachDetailExportTab: {
                paragraphs: [
                    "Met deze functie kun je een export maken van de chats van de coach. Na het exporteren zal er automatisch een CSV-bestand met de geselecteerde periode worden gedownload.",
                ],
                scheduledFromTill: "Periode",
                buttonText: "Exporteer",
            },
            CoachDetailTab1: {
                mainHeading: "Contactgegevens",
                firstName: "Voornaam",
                prefix: "Tussenvoegsel",
                lastName: "Achternaam",
                birthDate: "Geboortedatum",
                emailAddress: "E-mailadres",
                phoneNumber: "Telefoonnummer",
                IBAN: "IBAN",
                studyHeading: "Opleiding",
                study: "Studie",
                studyYear: "Jaar",
                addressHeading: "Adres",
                street: "Straat",
                houseNumber: "Nummer",
                zipcode: "Postcode",
                city: "Plaats",
                coursesHeading: "Vakken",
                statusHeading: "Status",
                active: "Actief",
                createdAt: "Startdatum",
                timeActive: "Tijd actief",
                lastUpdatedAt: "Profiel voor het laatst geüpdatet",
                submitButtonText: "Coach opslaan",
                successMessage: "Coach is opgeslagen.",
            },
            CoachesList: {
                heading: "Coaches",
                searchInputPlaceholder: "Zoek op naam, ID of e-mailadres",
                filterLabels: {
                    scheduledFromTill: "Van/tot ingeroosterd",
                    activeFromTill: "Datum actief",
                    courses: "Vakken",
                    active: "Actief",
                    inactive: "Inactief",
                    reset: "Reset",
                },
                coursesFilter: {
                    emptyOptionText: "Vakken",
                    submitButtonText: "Toepassen",
                },
                numberOfCoachesFound: {
                    begin: "Er zijn ",
                    end: " coaches gevonden",
                },
                columns: {
                    online: "Online",
                    id: "ID",
                    name: "Naam",
                    emailAddress: "E-mailadres",
                    phone: "Telefoonnr",
                    courses: "Vakken",
                    numberOfShifts:
                        "Inschrijvingen binnen geselecteerde periode",
                    createdAt: "Datum gestart",
                },
                noResultsText: "Geen coaches gevonden.",
                loadMoreResultsButtonText: "Laat meer coaches zien",
            },
            CoachSignUpsList: {
                heading: "Inschrijvingen",
                searchInputPlaceholder: "Zoeken",
                columns: {
                    id: "ID",
                    name: "Naam",
                    emailAddress: "E-mailadres",
                    createdAt: "Aanmaakdatum",
                    phone: "Telefoonnummer",
                    school: "School",
                    study: "Studie / vakken",
                    studyYear: "Jaar",
                    homeGuidance: "Thuisbegeleiding",
                },
                buttons: {
                    assessment: "Assessment",
                    activate: "Activeren",
                    delete: "Verwijderen",
                },
                loadMoreResultsButtonText: "Laat meer inschrijvingen zien",
            },
            CouponCodesList: {
                heading: "Couponcodes",
                addButtonText: "Toevoegen",
                searchInputPlaceholder: "Zoeken op naam of couponcode",
                columns: {
                    visible: "Zichtbaar",
                    name: "Naam",
                    description: "Beschrijving",
                    code: "Couponcode",
                    value: "Waarde in centen",
                    type: "Type",
                    createdAt: "Datum aangemaakt",
                    expiresAt: "Vervaldatum",
                },
                noResultsText: "Geen couponcodes gevonden.",
                noExpiry: 'n.v.t.',
                deleteMessage: 'Couponcode is gedeactiveerd',
                errorMessage: 'Er is iets misgegaan',
            },
            CouponCodeCreateOrUpdate: {
                create: {
                    heading: "Couponcode toevoegen",
                    submitButtonText: "Toevoegen",
                },
                update: {
                    heading: "Couponcode wijzigen",
                    submitButtonText: "Wijzigen",
                },
                cancelButtonText: "Annuleren",
                inputLabels: {
                    name: "Naam",
                    description: "Beschrijving",
                    couponCode: "Couponcode",
                    value: "Waarde in centen",
                    enabled: "Actief",
                    expiry: 'Vervaldatum',
                },
                alertSuccessMessage: "De couponcode is aangemaakt of aangepast",
                alertDeleteMessage: "De couponcode is uitgezet",
            },
            Home: {
                heading: "Online coaches",
                allCoachesInThisShift: "Alle coaches in deze shift",
                columns: {
                    id: "ID",
                    name: "Naam",
                    email: "E-mailadres",
                    phone: "Telefoonnr",
                    courses: "Vakken",
                    createdAt: "Datum gestart",
                },
                noCoachesInThisShift:
                    "Er zijn geen coaches ingeroosterd in deze shift.",
                noCoachesWithThisCourse: "Geen coaches ingeroosterd",
            },
            InternalUsersList: {
                heading: "Interne gebruikers",
                addButtonText: "Toevoegen",
                typeFilter: {
                    allTypes: "Alle rollen",
                    admin: "Admin",
                    monitor: "Monitor",
                    courseCaptain: "Vakaanvoerder",
                    onboarder: "Aansluiter",
                },
                searchInputPlaceholder: "Zoeken op naam of e-mailadres",
                columns: {
                    activated: "Geactiveerd",
                    secondFactorRequired: "2FA",
                    id: "ID",
                    name: "Naam",
                    email: "E‑mailadres",
                    type: "Rol",
                },
                noResultsText: "Geen interne gebruikers gevonden.",
            },
            ForgotPasswordModal: {
                heading: "Wachtwoord vergeten",
                paragraph:
                    "Voer hier het e-mailadres in waarmee je een account hebt aangemaakt, om je wachtwoord te resetten.",
                inputLabel: "E-mailadres",
                inputPlaceholder: "Wat is je e-mailadres?",
                cancelButtonText: "Annuleren",
                submitButtonText: "Verzenden",
                successText:
                    "Er is een e-mail verstuurd met daarin instructies over het opnieuw instellen van je wachtwoord.",
            },
            InternalUserCreateOrUpdate: {
                create: {
                    heading: "Interne gebruiker toevoegen",
                    submitButtonText: "Toevoegen",
                },
                update: {
                    heading: "Interne gebruiker wijzigen",
                    submitButtonText: "Wijzigen",
                },
                cancelButtonText: "Annuleren",
                inputs: {
                    firstNameLabel: "Voornaam",
                    prefixLabel: "Tussenvoegsel",
                    lastNameLabel: "Achternaam",
                    emailLabel: "E-mailadres",
                    typeLabel: "Rol",
                },
                alertSuccessMessage: "Gebruiker is aangemaakt of aangepast",
            },
            InternalUserDelete: {
                heading: "Interne gebruiker verwijderen",
                paragraphBegin: "Weet je zeker dat je ",
                paragraphEnd: " wilt verwijderen?",
                cancelButtonText: "Annuleren",
                submitButtonText: "Verwijderen",
                alertSuccessMessage: "Gebruiker is verwijderd",
            },
            StudentSubscriptionDelete: {
                heading: "Abonnement verwijderen",
                paragraphBegin: "Weet je zeker dat je ALLE bestellingen en ALLE Mollie recurring payments direct wilt stoppen van de volgende leerling",
                paragraphEnd: "?",
                cancelButtonText: "Annuleren",
                submitButtonText: "Verwijderen",
                alertSuccessMessage: "Abonnement is verwijderd",
            },
            LogIn: {
                heading: "Inloggen",
                emailAddress: "E-mailadres",
                emailAddressPlaceholder: "Wat is je e-mailadres?",
                password: "Wachtwoord",
                passwordPlaceholder: "Vul je wachtwoord in.",
                errorMessage:
                    "De combinatie van het e-mailadres en het wachtwoord is niet bij ons bekend.",
                submitButtonText: "Inloggen",
                forgotPasswordLinkText: "Wachtwoord vergeten?",
            },
            LogInWith2FA: {
                heading: "Inloggen",
                paragraph: "Voer de code in.",
                submitButtonText: "Verifiëren",
            },
            OrdersList: {
                heading: "Bestellingen",
                noResultsText: "Geen bestellingen gevonden.",
                stateFilter: "Status",
                subscriptionFilter: "Abonnementstype",
                searchInputPlaceholder: "Zoeken",
                columns: {
                    id: "ID",
                    check: "✔️",
                    createdAt: "Datum",
                    parentEmail: "E-mailadres ouder",
                    userEmail: "E-mailadres leerling",
                    price: "Bedrag",
                    couponCode: "Couponcode",
                    subscriptionName: "Type abonnement",
                    startDate: "Startdatum",
                    paymentStatus: "Betaalstatus",
                    contractEndsAt: "Einddatum",
                    paymentsButton: "Betalingen",
                },
                orderTypesFilter: {
                    activeSubscriptions: "Actief",
                    changedSubscriptions: "Aangepast",
                    canceledSubscriptions: "Opgezegd",
                },
                loadMoreResultsButtonText: "Laat meer bestellingen zien",
            },
            ordersListPaymentsModal: {
                heading: "Betalingsgegevens",
                columns: {
                    id: "ID",
                    amount: "Bedrag",
                    isHandled: "Behandeld",
                    isPaid: "Betaald",
                    paymentMethod: "Betaalmethode",
                    paymentDate: "Datum",
                    paymentResponse: "Log",
                },
            },
            OrganisationCreateOrUpdate: {
                create: {
                    heading: "Organisatie toevoegen",
                    submitButtonText: "Toevoegen",
                },
                update: {
                    heading: "Organisatie wijzigen",
                    submitButtonText: "Wijzigen",
                },
                cancelButtonText: "Annuleren",
                name: "Naam organisatie",
                type: "Type",
                brins: "BRINS",
                brin: "Brin nummer",
                partOfOrganisationGroup:
                    "Alleen geïmporteerde leerlingen kunnen inloggen",
                providerId: "Provider ID",
                startDate: "Startdatum",
                endDate: "Einddatum",
                domains: "Domeinen (embedded chat)",
                domainsPlaceholder: "example.com",
                chatBot: "ChatBot",
                bulkUpload: "Bulk upload",
                checkoutEnabled: "Checkout zichtbaar",
            },
            OrganisationAnonymization: {
                heading: "Anonimiseer organisatie",
                paragraphBegin: "Weet je zeker dat je organisatie ",
                paragraphEnd: " wilt anonimiseren?",
                paragraph2:
                    "Alle gegevens van deze organisatie worden dan anonimiserd. Dit kan niet ongedaan worden gemaakt!",
                cancelButtonText: "Annuleren",
                submitButtonText: "Anonimiseren",
                alertSuccessMessage: "Organisatie is succesvol geanonimiserd",
                
            },
            OrganisationDelete: {
                heading: "Organisatie verwijderen",
                paragraphBegin: "Weet je zeker dat je organisatie ",
                paragraphEnd: " wilt verwijderen?",
                paragraph2:
                    "Alle data van deze organisatie wordt dan verwijderd. Dit kan niet ongedaan worden gemaakt!",
                cancelButtonText: "Annuleren",
                submitButtonText: "Verwijderen",
            },
            OrganisationMagisterStudentsImport: {
                loadingMessage:
                    "De data wordt opgehaald bij een externe partij. Dit kan een tijdje duren...",
                heading: "Magister leerlingen import",
                brins: "Brins",
                dataCreatedAt: "Data aangemaakt op",
                lastImportAt: "Laatste import",
                totalStudentsInDatabase: "Aantal leerlingen in database",
                totalStudentsInImport: "Aantal leerlingen in import",
                schoolYear: "Schooljaar",
                selectAllStudentsButtonText:
                    "Alle leerlingen van alle klassen en clusters selecteren",
                selectAllImportedStudentsButtonText:
                    "Alle reeds geïmporteerde leerlingen (van alle klassen en clusters) selecteren",
                downloadTeachersButtonText: "Download docenten en klassen export",
                downloadStudentsButtonText: "Download leerlingen export",
                downloadingMessage: 'Het bestand wordt gedownload...',
                columns: {
                    groupName: "Klassen",
                    clusterName: "Clusters",
                    new: "nieuw",
                    update: "al geïmporteerd",
                },
                submitButtonText: "Importeer alle geselecteerde leerlingen",
                successMessageStart: "Er zijn ",
                successMessageMiddle:
                    " leerlingen aan onze database toegevoegd, en ",
                successMessageEnd: " leerlingen geüpdate.",
            },
            OrganisationsMagisterImport: {
                heading: "Magister import koppelingen",
                columns: {
                    name: "Naam",
                    tenantID: "Tenant ID",
                    lastConsentChange: "Laatste toegangswijziging",
                    attachToOrg: "Koppelen aan school",
                },
                emptyResultOption: "Selecteer een school...",
                successMessage: "De school is succesvol gekoppeld",
                modal: {
                    heading: "Weet je het zeker?",
                    paragraph:
                        "Weet je zeker dat je de scholen aan elkaar wilt koppelen?",
                    cancelButtonText: "Annuleren",
                    submitButtonText: "Ja, ik weet het zeker",
                },
            },
            OrganisationsSomtodayStudentsImport: {
                loadingMessage:
                    "De data wordt opgehaald bij een externe partij. Dit kan een tijdje duren...",
                heading: "Somtoday leerlingen import",
                brins: "Brins",
                dataCreatedAt: "Data aangemaakt op",
                lastImportAt: "Laatste import",
                totalStudentsInDatabase: "Aantal leerlingen in database",
                totalStudentsInImport: "Aantal leerlingen in import",
                schoolYear: "Schooljaar",
                selectAllStudentsButtonText:
                    "Alle leerlingen van alle klassen en clusters selecteren",
                selectAllImportedStudentsButtonText:
                    "Alle reeds geïmporteerde leerlingen (van alle klassen en clusters) selecteren",
                downloadTeachersButtonText: "Download docenten en klassen export",
                downloadStudentsButtonText: "Download leerlingen export",
                downloadingMessage: 'Het bestand wordt gedownload...',
                columns: {
                    groupName: "Klassen",
                    clusterName: "Clusters",
                    new: "nieuw",
                    update: "al geïmporteerd",
                },
                submitButtonText: "Importeer alle geselecteerde leerlingen",
                successMessageStart: "Er zijn ",
                successMessageMiddle:
                    " leerlingen aan onze database toegevoegd, en ",
                successMessageEnd: " leerlingen geüpdate.",
            },
            OrganisationsSomtodayImportCoupling: {
                heading: "Somtoday import koppelingen",
                columns: {
                    name: "Organisatie",
                    uuid: "UUID",
                },
                emptyResultOptionSelect: "Selecteer een school...",
                successMessage: "De school is succesvol gekoppeld",
                modal: {
                    heading: "Weet je het zeker?",
                    paragraph:
                        "Weet je zeker dat je de scholen aan elkaar wilt koppelen?",
                    cancelButtonText: "Annuleren",
                    submitButtonText: "Ja, ik weet het zeker",
                },
            },
            OrganisationUpload: {
                heading: "Bulk upload",
                selectedOrganisation: "Organisatie: ",
                selectFileButton: "Selecteer een .csv bestand",
                cloudMailerSelectLabel: "Cloudmailer automation",
                subscriptionTypeLabel: "Subscription type",
                resetButtonText: "Reset",
                submitButtonText: "Uploaden",
                successMessagePart1: "Gelukt! Er zijn ",
                successMessagePart2: " gebruikers toegevoegd, en ",
                successMessagePart3: " gebruikers aangepast.",
                errorMessage: "Er is iets misgegaan.",
            },
            OrganisationsList: {
                heading: "Scholen",
                activeOrInactiveToggle: "Actief/niet actief",
                addButtonText: "Toevoegen",
                searchInputPlaceholder: "Zoeken",
                uploadIconTitle: "Bulk upload",
                columns: {
                    id: "ID",
                    name: "Naam",
                    startDate: "Startdatum",
                    endDate: "Einddatum",
                    updatedAt: "Gewijzigd op",
                    type: "Type",
                    chatBot: "Chatbot",
                },
                noResultsText: "Geen organisaties gevonden.",
            },
            PushNotificationsList: {
                planned: {
                    heading: "Ingeplande pushberichten",
                    buttonText: "Verstuurde pushberichten",
                },
                archive: {
                    heading: "Verstuurde pushberichten",
                    buttonText: "Ingeplande pushberichten",
                },
                newButtonText: "Nieuw",
                searchInputPlaceholder: "Zoeken op doel of bericht",
            },
            PushNotificationCreateOrUpdate: {
                create: {
                    heading: "Nieuw pushbericht",
                    submitButtonText: "Toevoegen",
                },
                update: {
                    heading: "Pushbericht wijzigen",
                    submitButtonText: "Wijzigen",
                },
                levelLabel: "Niveau",
                studyYearLabel: "Leerjaar",
                studyYearOption: "Leerjaar ",
                subscriptionLabel: "Abonnement type",
                organisationLabel: "School",
                lastActiveLabel: "Laatst actief",
                lastActiveSelect: "Selecteer een periode",
                lastActiveOption: " geleden actief",
                selectionLabel: "Selectie:",
                studentsLabel: "Aantal leerlingen: ",
                goalLabel: "Doel:",
                dateLabel: "Datum & tijd:",
                messageLabel: "Bericht:",
                maxTotalCharacters: "/255",
                noStudentsFound: "Geen leerlingen gevonden",
                dateTimeErrorMessage:
                    "De datum en tijd moeten minimaal één uur en maximaal zes maanden in de toekomst liggen",
                massMessageWarningStart: "Je gaat naar ",
                massMessageWarningEnd: "leerlingen een bericht sturen",
                cancelButtonText: "Annuleren",
            },
            PushNotificationsTable: {
                columns: {
                    id: "ID",
                    timestamp: "Ingepland op",
                    goal: "Doel",
                    message: "Bericht",
                    studentCount: "Aantal leerlingen",
                    recipients: "Ontvangers",
                },
                noResultsText: "Geen pushberichten gevonden.",
                studyYear: "Leerjaar",
                deleteNotificationModal: {
                    heading: "Pushbericht verwijderen",
                    paragraph:
                        "Weet je zeker dat je dit pushbericht wilt verwijderen?",
                    cancelButtonText: "Annuleren",
                    submitButtonText: "Verwijderen",
                },
                loadMoreResultsButtonText: "Laat meer pushberichten zien",
            },
            RoleBadge: {
                Admin: "Admin",
                Monitor: "Monitor",
                CourseCaptain: "Vakaanvoerder",
                Onboarder: "Aansluiter",
            },
            Schedule: {
                heading: "Roosters",
                coachFilter: {
                    allSelected: "Alle coaches",
                },
                dateSeparator: " t/m ",
                createScheduleWeekButtonText: "Rooster aanmaken voor deze week",
                weekHeadingText: "Rooster week ",
                deleteScheduleButtonText: "Verwijderen",
                publishScheduleButtonText: "Zet live",
                noScheduleFound: "Er is geen rooster gevonden.",
                noScheduleWeekFound:
                    "Er is voor deze week geen rooster gevonden.",
                weekDeleteModal: {
                    heading: "Week verwijderen",
                    paragraph:
                        "Weet je zeker dat je deze week wilt verwijderen?",
                    cancelButtonText: "Annuleren",
                    submitButtonText: "Verwijderen",
                },
            },
            ScheduleCard: {
                totalCoachesLabel: "Coaches: ",
                totalMonitorsLabel: "Monitors: ",
                totalCoursesLabel: "Vakken: ",
            },
            ScheduleShiftModal: {
                updateShiftTimesButton: "Opslaan",
                optimizeShiftButton: "Shift optimaliseren",
                optimizeSuccessMessage: {
                    beginning: "Er is ",
                    end: " keer een coach aan een shift toegevoegd.",
                    alreadyOptimized:
                        "Deze shift kan op dit moment niet verder geoptimaliseerd worden.",
                },
                optimizeShiftErrorMessage: "Er is iets misgegaan. ",
                allCoursesScheduledCoachesHeading: "Ingeroosterde coaches",
                courseScheduledCoachesHeading: "Ingeroosterde coaches voor ",
                courseSpecificCoachesHeading: "Vak-specifieke coaches",
                otherCoachesHeading: "Overige coaches",
                unscheduleLink: "Uitschrijven",
                scheduleLink: "Inschrijven",
                scheduleAllCoursesLink: "Inschrijven voor alle bevoegde vakken",
                capacityLabel: "Capaciteit",
                deleteModal: {
                    heading: "Shift verwijderen",
                    paragraph:
                        "Weet je zeker dat je de shift wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.",
                    cancelButtonText: "Annuleren",
                    submitButtonText: "Verwijderen",
                    alertSuccessMessage: "De shift is verwijderd.",
                },
            },
            ScheduleShifts: {
                addShiftButtonText: "Shift toevoegen",
                minutes: "min.",
            },
            ScheduleShiftSelectedDay: {
                heading: "Nieuw rooster aanmaken",
                dateSeparator: " t/m ",
                days: [
                    "Maandag",
                    "Dinsdag",
                    "Woensdag",
                    "Donderdag",
                    "Vrijdag",
                    "Zaterdag",
                    "Zondag",
                ],
            },
            ScheduleShiftUpdate: {
                startTimeLabel: "Starttijd",
                endTimeLabel: "Eindtijd",
                warmupTimeLabel: "Warm-up tijd",
            },
            ScheduleWeekCreateModal: {
                saveButtonText: "Opslaan als concept",
                days: [
                    "Maandag",
                    "Dinsdag",
                    "Woensdag",
                    "Donderdag",
                    "Vrijdag",
                    "Zaterdag",
                    "Zondag",
                ],
                minutes: "min.",
                startTimeLabel: "Starttijd",
                endTimeLabel: "Eindtijd",
                warmupTimeLabel: "Warm-up tijd",
            },
            ScheduleWeekNavigation: {
                todayLabel: "Huidige week",
            },
            SignUpActivateModal: {
                heading: "Inschrijving activeren",
                paragraph:
                    "Weet je zeker dat je de inschrijving wilt activeren? De coach ontvangt een bevestigingsmail.",
                cancelButtonText: "Annuleren",
                submitButtonText: "Activeren",
                alertSuccessMessage: "De inschrijving is geactiveerd.",
            },
            SignUpDeleteModal: {
                heading: "Inschrijving verwijderen",
                paragraph:
                    "Weet je zeker dat je de inschrijving wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.",
                cancelButtonText: "Annuleren",
                submitButtonText: "Verwijderen",
                alertSuccessMessage: "De inschrijving is verwijderd.",
            },
            StudentDetail: {
                tabItems: ["Algemeen", "Chats", "Adres geschiedenis", "Export"],
                linkToHomeText: "Home",
                linkToStudentsListText: "Alle leerlingen",
            },
            StudentDetailExportTab: {
                paragraphs: [
                    "Met deze functie kun je een export maken van de chats van de leerling. Na het exporteren zal er automatisch een CSV-bestand met de geselecteerde periode worden gedownload.",
                ],
                scheduledFromTill: "Periode",
                buttonText: "Exporteer chats",
            },
            StudentDetailTabMain: {
                mainHeading: "Contactgegevens",
                firstName: "Voornaam",
                prefix: "Tussenvoegsel",
                lastName: "Achternaam",
                emailAddress: "E-mailadres/edentifier",
                contactEmailAddress: "Contact e-mailadres",
                addressHeading: "Adresgegevens",
                schoolHeading: "School",
                studyLabel: "Leerniveau",
                studyYearLabel: "Jaar",
                labelsHeading: "Labels",
                noUserLabelsFound:
                    "Er zijn voor deze leerling geen labels ingesteld.",
                financialHeading: "Financieel",
                subscriptionLabel: "Abonnement",
                creditsLabel: "Credits",
                botCreditsLabel: "Bot credits",
                extraCreditsLabel: "Extra credits",
                botExtraCreditsLabel: 'Bot extra credits',
                statusHeading: "Status",
                active: "Actief",
                createdAt: "Startdatum",
                timeActive: "Tijd actief",
                chatCountLabel: "Aantal chats",
                chatDurationLabel: "Aantal uur gechat",
                submitButtonText: "Opslaan",
                successMessage: "Wijzigingen succesvol opgeslagen",
                removeSubscriptionButtonText: "Verwijder abonnement",
            },
            StudentDetailTabHistory: {
                intro: "Tot de genoemde datum waren de gegevens als volgt:",
                columns: {
                    name: "Naam",
                    street: "Straat",
                    houseNumber: "Huisnummer",
                    postalCode: "Postcode",
                    city: "Woonplaats",
                    phoneNumber: "Telefoonnummer",
                    changedAt: "Datum",
                },
            },
            StudentsList: {
                heading: "Leerlingen",
                searchInputPlaceholder: "Zoek op naam, ID of e-mailadres",
                filterLabels: {
                    active: "Actief",
                    reset: "Reset",
                },
                columns: {
                    state: "Online",
                    id: "ID",
                    name: "Naam",
                    emailAddress: "E-mailadres",
                    organisation: "Organisatie",
                    subscription: "Abonnement",
                    active: "Actief",
                    createdAt: "Aangemaakt"
                },
                noResultsText: "Geen leerlingen gevonden.",
                loadMoreResultsButtonText: "Laat meer leerlingen zien",
                orgsFilter: {
                    emptyOptionText: "Organisaties",
                    submitButtonText: "Toepassen",
                },
                subsFilter: {
                    emptyOptionText: "Abonnementen",
                    submitButtonText: "Toepassen",
                },
            },
            UserDoesNotHaveRequiredRoleMessage: {
                heading: "Geen toegang",
                message:
                    "Met dit account heb je geen toegang tot deze weergave.",
            },
            UserLabelCreateOrUpdate: {
                create: {
                    heading: "Gebruikerlabel toevoegen",
                    submitButtonText: "Toevoegen",
                },
                update: {
                    heading: "Gebruikerlabel wijzigen",
                    submitButtonText: "Wijzigen",
                },
                cancelButtonText: "Annuleren",
                inputs: {
                    nameLabel: "Naam",
                    visibleForCoachLabel: "Zichtbaar voor coaches",
                },
                alertSuccessMessage:
                    "Gebruikerlabel is aangemaakt of aangepast",
            },
            UserLabelDelete: {
                heading: "Gebruikerlabel verwijderen",
                paragraphBegin: "Weet je zeker dat je ",
                paragraphEnd: " wilt verwijderen?",
                cancelButtonText: "Annuleren",
                submitButtonText: "Verwijderen",
                alertSuccessMessage: "Gebruikerlabel is verwijderd",
            },
            UserLabelsList: {
                heading: "Gebruikerlabels",
                addButtonText: "Gebruikerlabel toevoegen",
                searchInputPlaceholder: "Zoeken",
                columns: {
                    id: "ID",
                    name: "Naam",
                    visibleForCoach: "Zichtbaar voor coaches",
                },
                noResultsText: "Geen gebruikerlabels gevonden.",
            },
            UserLabelsTooltip: {
                labelHeading: "Labels",
            },
            // =====================================================
            // Pinia store translation keys
            // =====================================================
            session: {
                userDoesNotHaveRequiredRoleForAdmin: {
                    begin: "Het account waarmee je probeer in te loggen is een ",
                    end: "-account. Hiermee heb je geen toegang tot deze applicatie.",
                },
            },
        }
    },
})
