<template>
    <template v-if="!isLoading">
        <div class="v-app__wrapper">
            <SiteMenu v-if="!route.meta.noMenu && session.isLoggedIn" />
            <main class="v-app__main">
                <RouterView
                    :key="route.fullPath"
                    v-if="userHasRequiredRole(route.meta.requiredRoleAction as string)"
                ></RouterView>
                <UserDoesNotHaveRequiredRoleMessage v-else />
            </main>
        </div>
    </template>

    <McAlerts />
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue"
import { useRoute } from "vue-router"
import { useSessionStore } from "@/stores/session"
import { useMenuStore } from "@/stores/menu"
import { useRoleStore } from "@/stores/role"
import SiteMenu from "@/components/SiteMenu.vue"
import McAlerts from "@/assets/ui-components/vue-components/v2/McAlerts.vue"
import UserDoesNotHaveRequiredRoleMessage from "@/components/UserDoesNotHaveRequiredRoleMessage.vue"

const route = useRoute()
const session = useSessionStore()
const menu = useMenuStore()
const role = useRoleStore()

const paddingLeft = computed<string>(() => {
    if (route.meta.noMenu) {
        return "0px"
    } else {
        if (menu.maximised) {
            return menu.widthMax
        } else {
            return menu.widthMin
        }
    }
})

const isLoading = ref(true)

function userHasRequiredRole(requiredRoleAction: string): boolean {
    if (requiredRoleAction === undefined) {
        return true
    } else return role.can(requiredRoleAction)
}

onMounted(async () => {
    await session.getAuthToken()
    isLoading.value = false
})
</script>

<style lang="sass" scoped>
.v-app
    &__wrapper
        position: relative
        transition: padding 0.2s ease-in-out
        padding-left: v-bind(paddingLeft)

    &__main
        max-width: 2000px
        min-height: 100vh
        padding: 20px
        position: relative
</style>
