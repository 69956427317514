import { ref } from "vue"
import { defineStore } from "pinia"

export const useMenuStore = defineStore("menu", () => {
    const maximised = ref(localStorage.siteMenuMaximised !== "false")
    const subMenuIsOpen = ref(true)
    const widthMax = "245px"
    const widthMin = "64px"

    function toggle(): void {
        maximised.value = !maximised.value
        localStorage.siteMenuMaximised = maximised.value

        if (maximised.value) subMenuIsOpen.value = true
        else subMenuIsOpen.value = false
    }

    return { maximised, subMenuIsOpen, widthMax, widthMin, toggle }
})
