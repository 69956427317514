<template>
    <div class="c-card v-user-does-not-have-required-role-message">
        <div class="u-text-center">
            <h1 class="c-heading --small u-mb">
                {{ t.heading }}
            </h1>
            <div class="l-flex --with-gap --align-center --center">
                <McIcon file="alert-info" />
                <p class="c-p">
                    {{ t.message }}
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import McIcon from "@/assets/ui-components/vue-components/v2/McIcon.vue"
import { useI18nStore } from "@/stores/i18n"

const t = useI18nStore().UserDoesNotHaveRequiredRoleMessage
</script>

<style lang="sass" scoped>
.v-user-does-not-have-required-role-message
    height: calc(100vh - 40px)
    display: flex
    align-items: center
    justify-content: center
</style>
