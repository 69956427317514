import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import { createPinia } from "pinia"
import * as Sentry from "@sentry/vue"
import McSpinner from "./assets/ui-components/vue-components/v2/McSpinner.vue"
import VueKatex from '@hsorby/vue3-katex'
import 'katex/dist/katex.min.css'

const app = createApp(App)

app.component("McSpinner", McSpinner)

if (import.meta.env.VITE_USE_SENTRY === "true") {
    Sentry.init({
        app,
        dsn: "https://91efe16413974cacb5068e4f8cc00dca@o1098353.ingest.sentry.io/4505550688223232",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    "https://wouter-admin.mrchadd.nl:3001",
                    "https://chaddmin.mrchadd.nl",
                    "https://chaddmin.mrchadd.dev",
                ],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

app.use(router).use(createPinia()).use(VueKatex)


app.mount("#app")
