<template>
    <div class="v-site-menu" :class="menu.maximised ? '--max' : '--min'">
        <div class="v-site-menu__logo">
            <RouterLink :to="{ name: 'Home' }">
                <img :src="envVar.cdnPath + '/logo-black-on-transparent.svg'" />
            </RouterLink>
        </div>

        <div class="v-site-menu__menu">
            <template
                v-for="(menuItem, index) in $router.options.routes"
                :key="index"
            >
                <div
                    v-if="
                        !menuItem.meta?.notInMenu &&
                        userHasRequiredRole(menuItem.meta?.requiredRoleAction)
                    "
                    class="v-site-menu__menu-item"
                >
                    <button
                        @click="clickOn(menuItem.name, 'parent')"
                        :class="menuItem.name === $route.name ? '--active' : ''"
                    >
                        <img
                            :src="
                                iconPath(
                                    menuItem.name,
                                    selectedParent === index
                                )
                            "
                            class="v-site-menu__icon"
                        />
                        <template v-if="menu.maximised">
                            {{ menuItem.meta?.title }}
                        </template>
                    </button>

                    <div
                        class="v-site-menu__submenu"
                        v-if="
                            menuItem.children &&
                            selectedParent === index &&
                            menu.subMenuIsOpen &&
                            (menu.maximised ||
                                (!menu.maximised && menu.subMenuIsOpen))
                        "
                        :class="menu.maximised ? '--max' : '--min'"
                    >
                        <template
                            v-for="subItem in menuItem.children"
                            :key="subItem.name"
                        >
                            <div
                                v-if="
                                    userHasRequiredRole(
                                        subItem.meta?.requiredRoleAction
                                    ) && !subItem.meta?.notInMenu
                                "
                                class="v-site-menu__submenu-item"
                            >
                                <button
                                    @click="clickOn(subItem.name, 'child')"
                                    :class="
                                        subItem.name === $route.name
                                            ? '--active'
                                            : ''
                                    "
                                >
                                    {{ subItem.meta?.title }}
                                </button>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>

        <div class="v-site-menu__user">
            <div class="v-site-menu__menu-item">
                <button
                    @click="clickOn('Account', '')"
                    :class="'Account' === $route.name ? '--active' : ''"
                >
                    <McUserAvatar :id="session.profile.ID" :size="21" />
                    <template v-if="menu.maximised">
                        {{ session.profile.firstName }}
                        {{ session.profile.prefix }}
                        {{ session.profile.lastName }}
                    </template>
                </button>
            </div>
        </div>

        <div class="v-site-menu__toggle">
            <button @click="menu.toggle()">
                <img
                    :src="
                        menu.maximised
                            ? '/img/menu/min.svg'
                            : '/img/menu/max.svg'
                    "
                />
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import { useRouter, useRoute, RouteRecordName } from "vue-router"
import { useEnvVarStore } from "@/assets/ui-components/pinia-stores/envVar"
import { useMenuStore } from "@/stores/menu"
import { useSessionStore } from "@/stores/session"
import { useRoleStore } from "@/stores/role"
import McUserAvatar from "@/assets/ui-components/vue-components/v2/McUserAvatar.vue"

const router = useRouter()
const route = useRoute()
const envVar = useEnvVarStore()
const menu = useMenuStore()
const session = useSessionStore()
const role = useRoleStore()

const menuWidthMin = menu.widthMin
const menuWidthMax = menu.widthMax

const selectedParent = computed<number>(() => {
    const currentParent = route.matched[0]?.name
    const routes = router.options.routes
    let value: number = 0

    for (let i = 0; i < routes.length; i++) {
        if (routes[i].name === currentParent) {
            value = i
            break
        }
    }
    return value
})

function clickOn(name: any, type: string): void {
    router.push({ name: name })

    if (!menu.maximised) {
        if (type === "parent") {
            menu.subMenuIsOpen = true
        } else menu.subMenuIsOpen = false
    }
}

function iconPath(name: RouteRecordName | undefined, active: boolean): string {
    return (
        "/img/menu/" +
        name?.toString().toLowerCase() +
        (active ? "-active" : "") +
        ".svg"
    )
}

function userHasRequiredRole(requiredRoleAction: any): boolean {
    if (requiredRoleAction === undefined) {
        return true
    } else return role.can(requiredRoleAction)
}

onMounted(() => {
    // Open submenu when menu is maximised
    menu.subMenuIsOpen = menu.maximised
})
</script>

<style lang="sass" scoped>
.v-site-menu
    +box-shadow
    background-color: $box-bg-color
    position: fixed
    top: 0
    left: 0
    bottom: 0
    transition: width 0.2s ease-in-out, color 0.1s ease-out 0.1s
    display: grid
    grid-template-rows: auto 1fr auto auto
    +z-index(header)

    &__logo
        text-align: center
        padding: 30px 15px

        img
            max-width: 100%
            width: 65px

    &__menu
        border-top: 1px solid $border-color

    &__user
        border-top: 1px solid $border-color
        margin-bottom: 20px

    &.\--min
        width: v-bind(menuWidthMin)
        color: transparent

    &.\--max
        width: v-bind(menuWidthMax)
        color: $main-text-color
        overflow-y: auto

        .v-site-menu__menu-item
            img
                margin-right: 10px

    &__menu-item
        position: relative

        img
            display: inline-block
            width: 24px

        button
            padding: 18px 20px
            display: flex
            align-items: center
            width: 100%
            border-bottom: 1px solid $border-color
            transition: border 0.1s ease-in
            text-align: left

            &:hover
                background-color: $light-grey

            &.\--active
                background-color: $light-yellow
                font-weight: $bold
                border-left: 5px solid $dark-yellow

    &__submenu
        &.\--min
            position: absolute
            left: calc(100% - 10px)
            top: 0
            +box-shadow
            +border-radius
            background-color: $box-bg-color
            +z-index(tooltip)

            .v-site-menu__submenu-item
                button
                    padding: 10px 30px
                    color: $main-text-color
                    white-space: nowrap

    &__submenu-item
        button
            padding: 10px 40px 10px 60px

    &__toggle
        display: flex
        justify-content: flex-end

        button
            padding: 15px
</style>
