import { ref, computed } from "vue"
import { defineStore } from "pinia"
import router from "@/router"
import axios, { AxiosResponse } from "axios"
import * as Sentry from "@sentry/browser"

import { useEnvVarStore } from "@/assets/ui-components/pinia-stores/envVar"
import { useAlertStore } from "@/assets/ui-components/pinia-stores/alert"
import { useI18nStore } from "./i18n"

export const useSessionStore = defineStore("session", () => {
    const envVar = useEnvVarStore()
    const alert = useAlertStore()
    const t = useI18nStore().session

    const authToken = ref("")
    const isLoggedIn = ref(false)
    const profile = ref({
        firstName: "",
        prefix: "",
        lastName: "",
        ID: "",
        type: "",
        email: "",
        landingPage: "",
    })

    interface ApiHeader {
        headers: {
            Authorization: string
        }
    }

    const apiHeaders = computed<ApiHeader>(() => {
        return {
            headers: {
                Authorization: "Bearer " + authToken.value,
            },
        }
    })

    async function logIn(data: object | any): Promise<void> {
        authToken.value = data.key
        isLoggedIn.value = true

        // Save token in localStorage so user stays logged in
        localStorage.authToken = data.key

        if (data.secondFactorRequired) {
            if (data.authMethods.totp) {
                router.push({ name: "LogInWith2FA" })
            } else {
                router.push({ name: "AccountSetUp2FA" })
            }
        } else {
            // Get profile data
            await getProfile()

            // Take user to their preferred landing page
            // If it is set, otherwise default to "Home"
            router.push({
                name:
                    profile.value.landingPage !== ""
                        ? profile.value.landingPage
                        : "Home",
            })
        }
    }

    async function getAuthToken(): Promise<void> {
        // Get token from localStorage
        authToken.value = localStorage.getItem("authToken") as string

        // If token exists, treat user as logged in
        if (authToken.value !== null) {
            isLoggedIn.value = true

            // Refresh token
            await refreshToken()

            // Get profile data
            await getProfile()
        }
    }

    async function getProfile(): Promise<void> {
        return axios
            .get(envVar.apiBaseUrl + "/v1/user/profile", apiHeaders.value)
            .then((response) => {
                profile.value = response.data

                Sentry.setUser({
                    username:
                        profile.value.firstName + " " + profile.value.lastName,
                    email: profile.value.email,
                })

                // Log user out and redirect to log-in page when user has
                // a user type that does not have access to the admin
                if (
                    profile.value.type !== "Admin" &&
                    profile.value.type !== "Monitor" &&
                    profile.value.type !== "CourseCaptain" &&
                    profile.value.type !== "Onboarder"
                ) {
                    const message =
                        t.userDoesNotHaveRequiredRoleForAdmin.begin +
                        profile.value.type.toLowerCase() +
                        t.userDoesNotHaveRequiredRoleForAdmin.end
                    alert.show(message, "error", true, true)

                    localStorage.removeItem("authToken")
                    isLoggedIn.value = false
                    router.push({ name: "LogIn" })
                }
            })
            .catch(() => {
                // If endpoint fails, user is not properly authenticated,
                // send user to log-in page
                isLoggedIn.value = false
                router.push({ name: "LogIn" })
            })
    }

    function refreshToken(): Promise<void | AxiosResponse> {
        return axios
            .post(
                envVar.apiBaseUrl + "/v1/user/refresh-token",
                {},
                apiHeaders.value
            )
            .catch((error) => {
                // If endpoint fails, user is not properly authenticated,
                // remove auth token in localStorage and refresh page,
                // whichever page you are on (could be the "password reset" page)
                localStorage.removeItem("authToken")
                window.location.reload()
            })
    }

    function logOut(): void {
        localStorage.removeItem("authToken")

        axios
            .post(envVar.apiBaseUrl + "/v1/user/logout", {}, apiHeaders.value)
            .finally(() => {
                window.location.href = "/" // full page refresh so Pinia state is also reset
            })
    }
    return {
        authToken,
        isLoggedIn,
        profile,
        apiHeaders,
        logIn,
        getAuthToken,
        getProfile,
        refreshToken,
        logOut,
    }
})
