import { defineStore } from "pinia"

export const useAlertStore = defineStore("alert", {
    state: () => {
        return {
            alerts: Array<object | any>(),
            duration: 5000,
        }
    },
    actions: {
        show(
            message: string,
            type: string,
            closable: boolean,
            autoClose: boolean
        ) {
            // Generate unique ID to be able to identify the alert
            const uid = Math.random()
                .toString(16)
                .slice(2)

            const obj = {
                uid: uid,
                type: type,
                message: message,
                closable: closable,
                autoClose: autoClose,
            }

            this.alerts.push(obj)

            if (autoClose) {
                // Remove alert object from alerts array
                setTimeout(() => {
                    for (let i = 0; i < this.alerts.length; i++) {
                        if (this.alerts[i].uid === uid) {
                            this.alerts.splice(i, 1)
                        }
                    }
                }, this.duration)
            }
        },
        close(uid: string) {
            for (let i = 0; i < this.alerts.length; i++) {
                if (this.alerts[i].uid === uid) {
                    this.alerts.splice(i, 1)
                }
            }
        },
    },
})
