import { defineStore } from "pinia"

export const useEnvVarStore = defineStore("envVar", {
    state: () => {
        return {
            apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
            cdnPath: import.meta.env.VITE_CDN_PATH,
            mode: import.meta.env.MODE,
            basePath: import.meta.env.VITE_BASE_PATH,
            legacyAppBaseUrl: import.meta.env.VITE_LEGACY_APP_BASE_URL,
        }
    },
})
