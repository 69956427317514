<template>
    <div class="mc-alerts">
        <transition-group name="list">
            <div
                v-for="item in alert.alerts"
                :key="item.uid"
                class="mc-alerts__alert"
                :class="'--' + item.type"
            >
                <div class="mc-alerts__image">
                    <img :src="imageSrc(item.type)" />
                </div>
                <div>
                    <p class="c-p">{{ item.message }}</p>
                </div>
                <button
                    class="mc-alerts__close"
                    @click="alert.close(item.uid)"
                    v-if="item.closable"
                >
                    <img
                        src="https://cdn.mrchadd.nl/ui-components/img/icons/close-button.svg"
                    />
                </button>
            </div>
        </transition-group>
    </div>
</template>

<script lang="ts" setup>
import { useAlertStore } from "@/assets/ui-components/pinia-stores/alert"

const alert = useAlertStore()

function imageSrc(type: string) {
    return (
        "https://cdn.mrchadd.nl/ui-components/img/icons/alert-" + type + ".svg"
    )
}
</script>

<style lang="sass" scoped>
.mc-alerts
    position: fixed
    width: 400px
    max-width: 90%
    +z-index(alert)

    +mobile
        top: 15px
        right: 15px

    +tablet-and-up
        top: $standard-white-space * 1.5
        right: $standard-white-space * 1.5

    &__alert
        position: relative
        color: $inverted-text-color
        padding: $standard-white-space
        font-size: $small
        font-weight: $semi-bold
        +border-radius
        margin-bottom: $standard-white-space
        display: grid
        grid-template-columns: auto 1fr
        grid-gap: 10px
        align-items: center
        +box-shadow

        &.\--error
            background-color: $red

        &.\--success
            background-color: $green

    &__image
        width: 24px
        height: 24px
        position: relative

        img
            max-width: 100%
            max-height: 100%
            position: absolute
            +center

    &__close
        $size: 24px

        top: (-$size * 0.5)
        right: (-$size * 0.5)
        width: $size
        height: $size
        position: absolute
        cursor: pointer

.list-enter-active,
.list-leave-active
    transition: all 0.5s ease-in-out

.list-enter-from
    transform: translateX(100%)

.list-leave-to
    opacity: 0
</style>
