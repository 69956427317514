<template>
    <div class="mc-icon" v-html="file"></div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue"
import { useEnvVarStore } from "@/assets/ui-components/pinia-stores/envVar"

const envVar = useEnvVarStore()
const file = ref("")

const props = defineProps({
    file: {
        type: String,
        required: true,
    },
})

function readFile() {
    return fetch(envVar.cdnPath + "/icons/" + props.file + ".svg")
        .then((response) => response.text())
        .then((text) => {
            file.value = text
        })
}

onMounted(() => {
    readFile()
})
</script>

<style lang="sass">
.mc-icon
    svg
        display: block
</style>
