<script>
// This component is made for all applications that use Vite
//
export default {
    name: "McUserAvatar",
    props: {
        id: String,
        size: Number,
        cacheBusting: Boolean,
    },
    computed: {
        imageSource() {
            let value =
                import.meta.env.VITE_LEGACY_APP_BASE_URL +
                "/profile-image/" +
                this.id +
                "/" +
                this.size * 2 +
                "/" +
                this.size * 2 +
                "/img.png"

            if (this.cacheBusting) {
                value += "?v=" + Date.now()
            }
            return value
        },
    },
}
</script>

<template>
    <img
        :src="imageSource"
        class="v-user-avatar"
        :style="
            'width: ' +
            size +
            'px; height: ' +
            size +
            'px; min-width: ' +
            size +
            'px'
        "
        draggable="false"
    />
</template>

<style lang="sass" scoped>
.v-user-avatar
    border-radius: 50%
    display: block
    object-fit: cover
</style>
