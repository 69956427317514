import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import { useSessionStore } from "@/stores/session"

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: () => import("@/views/EmptyParentView.vue"),
        meta: {
            title: "Home",
            requiresAuth: true,
            notInMenu: true, // Remove this as soon as "home" starts existing
        },
    },
    {
        path: "/chats",
        name: "Chats",
        component: () => import("@/views/EmptyParentView.vue"),
        meta: {
            title: "Chats",
            requiresAuth: true,
        },
        children: [
            {
                path: "/chats/live",
                name: "ChatsLive",
                component: () => import("@/views/ChatsLive.vue"),
                meta: {
                    title: "Live chats",
                    requiresAuth: true,
                    requiredRoleAction: "manage-chats",
                },
            },
            {
                path: "/chat/:id",
                name: "ChatDetail",
                component: () => import("@/views/ChatDetail.vue"),
                meta: {
                    title: "Chat",
                    requiresAuth: true,
                    requiredRoleAction: "manage-chats",
                    notInMenu: true,
                    hideFromLandingPageSelection: true,
                },
            },
            {
                path: "/chats/grid",
                name: "ChatsGridView",
                component: () => import("@/views/ChatsGridView.vue"),
                meta: {
                    title: "Live chats grid",
                    requiresAuth: true,
                    requiredRoleAction: "manage-chats",
                },
            },
            {
                path: "/chats/archief",
                name: "ChatsArchive",
                component: () => import("@/views/ChatsArchive.vue"),
                meta: {
                    title: "Archief",
                    requiresAuth: true,
                    requiredRoleAction: "view-archive",
                },
            },
        ],
    },
    {
        path: "/scholen",
        name: "Organisations",
        component: () => import("@/views/EmptyParentView.vue"),
        meta: {
            title: "Scholen",
            requiresAuth: true,
            requiredRoleAction: "manage-organisations",
        },
        children: [
            {
                path: "/scholen/alle-scholen",
                name: "OrganisationsList",
                component: () => import("@/views/OrganisationsList.vue"),
                meta: {
                    title: "Scholen",
                    requiresAuth: true,
                    requiredRoleAction: "manage-organisations",
                },
            },
            {
                path: "/scholen/magister-leerlingen-import/:id",
                name: "OrganisationMagisterStudentsImport",
                component: () =>
                    import("@/views/OrganisationMagisterStudentsImport.vue"),
                meta: {
                    title: "Magister leerlingen import",
                    requiresAuth: true,
                    requiredRoleAction: "manage-organisations",
                    notInMenu: true,
                },
            },
            {
                path: "/scholen/magister-import-koppelingen",
                name: "OrganisationsMagisterImportCoupling",
                component: () =>
                    import("@/views/OrganisationsMagisterImportCoupling.vue"),
                meta: {
                    title: "Magister import koppelingen",
                    requiresAuth: true,
                    requiredRoleAction: "manage-organisations",
                },
            },
            {
                path: "/scholen/somtoday-leerlingen-import/:id",
                name: "OrganisationSomtodayStudentsImport",
                component: () =>
                    import("@/views/OrganisationSomtodayStudentsImport.vue"),
                meta: {
                    title: "Somtoday leerlingen import",
                    requiresAuth: true,
                    requiredRoleAction: "manage-organisations",
                    notInMenu: true,
                },
            },
            {
                path: "/scholen/somtoday-import-koppelingen",
                name: "OrganisationsSomtodayImportCoupling",
                component: () =>
                    import("@/views/OrganisationsSomtodayImportCoupling.vue"),
                meta: {
                    title: "Somtoday import koppelingen",
                    requiresAuth: true,
                    requiredRoleAction: "manage-organisations",
                },
            },
        ],
    },
    {
        path: "/leerlingen",
        name: "Students",
        component: () => import("@/views/EmptyParentView.vue"),
        meta: {
            title: "Leerlingen",
            requiresAuth: true,
            requiredRoleAction: "manage-students",
        },
        children: [
            {
                path: "/leerlingen/alle-leerlingen",
                name: "StudentsList",
                component: () => import("@/views/StudentsList.vue"),
                meta: {
                    title: "Leerlingen",
                    requiresAuth: true,
                    requiredRoleAction: "manage-students",
                },
            },
            {
                path: "/leerlingen/labels",
                name: "UserLabelsList",
                component: () => import("@/views/UserLabelsList.vue"),
                meta: {
                    title: "Labels",
                    requiresAuth: true,
                    requiredRoleAction: "manage-user-labels",
                },
            },
            {
                path: "/leerling/:id",
                name: "StudentDetail",
                component: () => import("@/views/StudentDetail.vue"),
                meta: {
                    title: "Leerling",
                    requiresAuth: true,
                    requiredRoleAction: "manage-students",
                    notInMenu: true,
                    hideFromLandingPageSelection: true,
                },
            },
        ],
    },
    {
        path: "/coaches",
        name: "Coaches",
        component: () => import("@/views/EmptyParentView.vue"),
        meta: {
            title: "Coaches",
            requiresAuth: true,
            requiredRoleAction: "manage-coaches",
        },
        children: [
            {
                path: "/coaches/online",
                name: "CoachesTodayShiftsOverview",
                component: () =>
                    import("@/views/CoachesTodayShiftsOverview.vue"),
                meta: {
                    title: "Online",
                    requiresAuth: true,
                    requiredRoleAction: "manage-coaches",
                },
            },
            {
                path: "/coaches/rooster",
                name: "Schedule",
                component: () => import("@/views/Schedule.vue"),
                meta: {
                    title: "Rooster",
                    requiresAuth: true,
                    requiredRoleAction: "manage-schedule",
                },
            },
            {
                path: "/coaches/alle-coaches",
                name: "CoachesList",
                component: () => import("@/views/CoachesList.vue"),
                meta: {
                    title: "Coaches",
                    requiresAuth: true,
                    requiredRoleAction: "manage-coaches",
                },
            },
            {
                path: "/coaches/inschrijvingen",
                name: "CoachSignUpsList",
                component: () => import("@/views/CoachSignUpsList.vue"),
                meta: {
                    title: "Inschrijvingen",
                    requiresAuth: true,
                    requiredRoleAction: "manage-coaches",
                },
            },
            {
                path: "/coaches/:id",
                name: "CoachDetail",
                component: () => import("@/views/CoachDetail.vue"),
                meta: {
                    title: "Coach",
                    requiresAuth: true,
                    requiredRoleAction: "manage-coaches",
                    notInMenu: true,
                    hideFromLandingPageSelection: true,
                },
            },
        ],
    },
    {
        path: "/beheer",
        name: "Admin",
        component: () => import("@/views/EmptyParentView.vue"),
        meta: {
            title: "Beheer",
            requiresAuth: true,
        },
        children: [
            {
                path: "/beheer/interne-gebruikers",
                name: "InternalUsersList",
                component: () => import("@/views/InternalUsersList.vue"),
                meta: {
                    title: "Interne gebruikers",
                    requiresAuth: true,
                    requiredRoleAction: "manage-internal-users",
                },
            },
            {
                path: "/beheer/bestellingen",
                name: "OrdersList",
                component: () => import("@/views/OrdersList.vue"),
                meta: {
                    title: "Bestellingen",
                    requiresAuth: true,
                    requiredRoleAction: "view-orders",
                },
            },
            {
                path: "/beheer/ingeplande-pushberichten",
                name: "PushNotificationsList",
                component: () => import("@/views/PushNotificationsList.vue"),
                props: { archiveMode: false },
                meta: {
                    title: "Pushberichten",
                    requiresAuth: true,
                    requiredRoleAction: "manage-push-notifications",
                },
            },
            {
                path: "/beheer/verstuurde-pushberichten",
                name: "PushNotificationsArchive",
                component: () => import("@/views/PushNotificationsList.vue"),
                props: { archiveMode: true },
                meta: {
                    title: "Verstuurde pushberichten",
                    requiresAuth: true,
                    notInMenu: true,
                    requiredRoleAction: "manage-push-notifications",
                },
            },
            {
                path: "/beheer/exporteer-data",
                name: "Exports",
                component: () => import("@/views/Exports.vue"),
                meta: {
                    title: "Exporteer data",
                    requiresAuth: true,
                    requiredRoleAction: "view-exports-page",
                },
            },
            {
                path: "/beheer/couponcodes",
                name: "CouponCodesList",
                component: () => import("@/views/CouponCodesList.vue"),
                meta: {
                    title: "Couponcodes",
                    requiresAuth: true,
                    requiredRoleAction: "view-coupon-codes",
                },
            },
        ],
    },
    // Auth
    {
        path: "/inloggen",
        name: "LogIn",
        component: () => import("@/views/LogIn.vue"),
        meta: {
            title: "Inloggen",
            noMenu: true,
            notInMenu: true,
            requiresAuth: false,
            hideFromLandingPageSelection: true,
        },
    },
    {
        path: "/2fa-code-invoeren",
        name: "LogInWith2FA",
        component: () => import("@/views/LogInWith2FA.vue"),
        meta: {
            title: "2FA code invoeren",
            requiresAuth: false,
            noMenu: true,
            notInMenu: true,
            hideFromLandingPageSelection: true,
        },
    },
    {
        path: "/account-activeren/:token",
        name: "AccountActivate",
        component: () => import("@/views/AccountActivate.vue"),
        meta: {
            title: "Account activeren",
            noMenu: true,
            notInMenu: true,
            requiresAuth: false,
            hideFromLandingPageSelection: true,
        },
    },
    {
        path: "/2fa-instellen",
        name: "AccountSetUp2FA",
        component: () => import("@/views/AccountSetUp2FA.vue"),
        meta: {
            title: "2FA instellen",
            requiresAuth: false,
            noMenu: true,
            notInMenu: true,
            hideFromLandingPageSelection: true,
        },
    },
    {
        path: "/wachtwoord-resetten/:token",
        name: "AccountResetPassword",
        component: () => import("@/views/AccountResetPassword.vue"),
        meta: {
            title: "Reset password",
            noMenu: true,
            notInMenu: true,
            requiresAuth: false,
            hideFromLandingPageSelection: true,
        },
    },
    // User
    {
        path: "/account",
        name: "Account",
        component: () => import("@/views/Account.vue"),
        meta: {
            title: "Account",
            notInMenu: true,
            requiresAuth: true,
        },
    },
    {
        path: "/image-viewer",
        name: "Image viewer",
        meta: {
            title: "Image viewer",
            noMenu: true,
            notInMenu: true,
            requiresAuth: true,
        },
        component: () => import("@/views/ChatImageViewer.vue"),
    },
    // 404 route
    {
        path: "/:catchAll(.*)*",
        name: "Mc404Page",
        component: () =>
            import("@/assets/ui-components/vue-components/v2/Mc404Page.vue"),
        meta: {
            title: "404",
            requiresAuth: false,
            notInMenu: true,
            hideFromLandingPageSelection: true,
        },
    },
    // Example route
    // {
    //     path: "/xxxxx",
    //     name: "Xxxxx",
    //     component: () => import("@/views/PlaceholderView.vue"),
    //     meta: {
    //         title: "Xxxxx",
    //         requiresAuth: true,
    //         noMenu: false,
    //         notInMenu: true,
    //     },
    // },
]
const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    const session = useSessionStore()

    // Set title tag
    document.title = to.meta.title + " - Chaddmin"

    let siteMenuMaximised = true
    if (localStorage.getItem("siteMenuMaximised") !== null)
        siteMenuMaximised = localStorage.getItem("siteMenuMaximised") === "true"

    // If user is not logged in, redirect to log-in page
    if (to.meta.requiresAuth && !session.isLoggedIn) {
        next({ name: "LogIn" })
    }

    // Redirect home (which doesn't exist yet) to ChatsLive
    else if (to.name === "Home") next({ name: "ChatsLive" })
    // Redirect empty parent views to 1st child view, but only if site menu is maximised
    else if (to.name === "Chats" && siteMenuMaximised) {
        next({ name: "ChatsLive" })
    } else if (to.name === "Organisations" && siteMenuMaximised)
        next({ name: "OrganisationsList" })
    else if (to.name === "Students" && siteMenuMaximised)
        next({ name: "StudentsList" })
    else if (to.name === "Coaches" && siteMenuMaximised)
        next({ name: "CoachesTodayShiftsOverview" })
    else if (to.name === "Admin" && siteMenuMaximised)
        next({ name: "InternalUsersList" })
    else next()
})

// Safeguard to not break the application for active users during a deployment
// router.onError((error, to: any) => {
//     if (error.message.includes("Failed to fetch dynamically imported module")) {
//         window.location = to.fullPath
//     }
// })

router.onError((error) => {
    console.error('router.onError', error)

    const isDeployError =
        error.message.includes('Failed to fetch dynamically imported module') ||
        error.message.includes('Importing a module script failed')

    if (isDeployError) errorReload(`Error during page load, ${error.message}`)
})

export const errorReload = (error: string, retries = 1) => {
    // Get the current URL
    const urlString = window.location.href

    // Create a URL object
    const url = new URL(urlString)
    const errRetries = parseInt(url.searchParams.get('errRetries') || '0')
    if (errRetries >= retries) {
        window.history.replaceState(null, '', url.pathname)
        // useSnackbarStore().add(error, { reload: true })
        return
    }

    // Update or add the query parameter
    url.searchParams.set('errRetries', String(errRetries + 1))

    // Reload the page with the updated URL
    window.location.href = url.toString()
}
  

export default router
